/** @jsx jsx */
import { jsx, Container, Heading } from "theme-ui"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { Layout, SEO, ContentfulRichText } from "../components"
import GetCashOfferForm from "../forms/get-cash-offer"

const About = ({ data }) => {
  const {
    title,
    headingTitle,
    headingSubtitle,
    description,
    image,
  } = data.contentfulAboutUsTemplate

  return (
    <Layout>
      <SEO title={title} />
      <Container sx={{ px: 3, py: [4, 5, 100] }}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", null, "2fr 1fr"],
            gridGap: [4, 5],
          }}
        >
          <div sx={{ textAlign: "center" }}>
            <Heading as="h1" sx={{ fontSize: 5, mb: 3 }}>
              {headingTitle}
            </Heading>
            <div sx={{ fontSize: 3, mb: [4, 5] }}>{headingSubtitle}</div>
            <Image
              sx={{
                border: theme => `6px solid ${theme.colors.gray3}`,
                width: "300px",
                height: "300px",
                borderRadius: "50%",
                ml: "auto",
                mr: "auto",
                mb: 4,
              }}
              fluid={image.fluid}
            />
            <ContentfulRichText
              sx={{ textAlign: "left" }}
              content={description}
            />
          </div>
          <div>
            <GetCashOfferForm />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulAboutUsTemplate(title: { eq: "About Us" }) {
      id
      title
      headingTitle
      image {
        ...CustomContentfulFluid
      }
      headingSubtitle
      description {
        raw
      }
    }
  }
`

export default About